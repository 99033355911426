import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Layout({main}) {
  return (
    <div className='container'>
      <Header />
      <main>
        {main}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
