import React, {useEffect, useState} from 'react'
import axios from 'axios'
import MyModal from './MyModal'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import TestEmail from './TestEmail'

function SendNewsletter() {

    const [cookies] = useCookies(["accessToken"])
    const [waitingForSendData, setWaitingForSendData] = useState([])
    const [loading, setIsloading] = useState(false)
    const [sending, SetIsSending] = useState(false)
    const [error, setError] = useState("")
    const [offset, setOffset] = useState(0)
    const [toBeSentNewsletters, setToBeSentNewsletters] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState("")
    const navigate = useNavigate()
    const [reLoginNeeded, setReLoginNeeded] = useState(false)
    const [testEmailId, setTestEmailId] = useState([])

    useEffect(()=>{
        fetchUnsentData()
    }, [])

    useEffect(()=>{
        //amikor "lapozunk" a kiküldésre váró levelek között, akkor aktualizálni kell a küldésre váró levelek azonosítóját a képernyőn láthatókéra
        const toBeSentIDs = waitingForSendData
            .filter((item, ind) => (ind >= offset && ind < offset+10))
            .map(item => { 
                return { id: item.id_newsletters }
            })
        setToBeSentNewsletters(toBeSentIDs)
        //console.log(offset)
    }, [offset, waitingForSendData])

    useEffect(()=>{
        if (toBeSentNewsletters.length > 0) {
            const temp = [toBeSentNewsletters[0]]
            setTestEmailId(temp)
        }
    },[toBeSentNewsletters])

    //a kiküldésre váró hírlevelek lekérése
    async function fetchUnsentData() {
        setIsloading(true)
        setError("")
        const url = `${process.env.REACT_APP_API_URL}/newsletter/getunsentdata`;
        try {
            const {data} = await axios.get(url, { headers: 
                { Authorization: `Bearer ${cookies.accessToken}` }
            })
            //console.log(data)
            if (Array.isArray(data) && data.length > 0) {
                setWaitingForSendData(data)
            } else {
                setError("Jelenleg nincs kiküldésre váró hírlevél")
            }
        } catch (err) {
            //console.log(err)
            setError(err?.response?.data ?? err?.message ?? "Hiba")
            if (err?.response?.status === 401) {
                setDialogContent(
                    err?.response?.data ??
                    err?.message ??
                    "Váratlan hiba történt."
                )
                setReLoginNeeded(true)
                openDialog()
            }
        } finally {
            setIsloading(false)
        }
    }

    const openDialog = () => {
        setIsOpen(true)
    }

    const closeDialog = () => {
        setIsOpen(false)
        //a dialog bezárása után újra le kell kérni a küldésre váró emailek listáját, hogy aktualizáljuk azt
        if (error !== "" && reLoginNeeded) {
            //ha valamilyen hibát () jeleztünk ki a Dialog-al, akkor:
            navigate("/login")
        } else {
            fetchUnsentData()
        }
    }

    function statusTranslator(status) {
        if (status === 'PENDING') return 'Kiküldésre vár'
        if (status === 'SENT') return 'Elküldve'
        if (status === 'UNSENT') return 'Sikertelen küldés'
        return status
    }
    //A kiküldésre váró levelek megjelenítése
    function renderUnsentData() {
        const tableData = waitingForSendData
            .map((item, ind) =>
                <tr key={ind}>
                    <td>{ind + 1}</td>
                    <td>{item.id_newsletters}</td>
                    <td>{item.email}</td>
                    <td>{item.subject}</td>
                    <td>{statusTranslator(item.status)}</td>
                </tr>)
            .filter((item, ind) => (ind >= offset && ind < offset+10))
        
        return <table>
                    <caption>
                        {`Összesen ${waitingForSendData.length} kiküldésre váró levél`}
                    </caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Azonosító</th>
                            <th>Email cím</th>
                            <th>Levél tárgya</th>
                            <th>Levél állapota</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
    }

    function renderPagination() {
        let maxOffset
        if (waitingForSendData.length <= 10)
            maxOffset = 10
        else {
            const remainder = waitingForSendData.length % 10
            if (remainder === 0) {
                maxOffset = waitingForSendData.length
            } else {
                maxOffset = (10 - remainder) + waitingForSendData.length
            }
            
        }
             
        const paginationButtons = <div className="pagination">
            <div className="prev">
                <button 
                    disabled={offset < 10}
                    onClick={handlePrev}
                    >Előző oldal
                </button>
            </div>
            <div className="forw">
                <button 
                    disabled={offset + 10 >= maxOffset}
                    onClick={handleNext}
                >Következő oldal
                </button>
            </div>
        </div>
        return paginationButtons
    }

    function handlePrev() {
        setOffset(prev=>prev-10)
    }

    function handleNext() {
        setOffset(prev=>prev+10)
    }

    //A hírlevelek küldését indító fgv
    async function sendNewsletters() {
        SetIsSending(true)
        setError("")
        const url = `${process.env.REACT_APP_API_URL}/newsletter/send-v2`;
        try {
            const {data} = await axios.post(
                url, 
                {data: toBeSentNewsletters},
                { headers: 
                    { Authorization: `Bearer ${cookies.accessToken}` }
                }
            )
            //console.log(data)
            if (Array.isArray(data?.msg)) {
                const modalContent = processingResponseMsg(data.msg)
                setDialogContent(modalContent)
                openDialog()
            } else {
                setError(data?.msg ?? "Hiba a levelek küldése során")
            }
        } catch (err) {
            setError(err?.response?.data ?? err?.message ?? "Hiba")
            if (err?.response?.status === 401) {
                setDialogContent(
                    err?.response?.data ??
                    err?.message ??
                    "Váratlan hiba történt."
                )
                setReLoginNeeded(true)
                openDialog()
            }
        } finally {
            SetIsSending(false)
        }
    }

    //A sikeres emailküldést követően egy objektumtömböt kapunk vissza, amiben az egyes objektumok 'value' kulcsa alatt érhető adott email státuszára vonatkozó üzenet. A fgv. ezeket szervezi egy listába
    const processingResponseMsg = (msgArray) => {
        const msgList = msgArray.map((item, ind) => 
            <li key={ind}>{item.value}</li>)
        return <ol>{msgList}</ol>
    }

  return (
    <div>
      <h2>Hírlevél kiküldése</h2>
      <div>
        {error !== "" ? 
            <div className="error">{error}</div> 
        : 
            (<div>
                <TestEmail newsletterId={testEmailId} />
                <div>
                    {loading ? "Betöltés folyamatban..." : renderUnsentData()}
                </div>
            
                <div>
                    {renderPagination()}
                </div>
                <div className="sendBtn">
                    <button 
                        onClick={sendNewsletters}
                        disabled={sending || waitingForSendData.length === 0}
                    >
                        A fenti hírlevelek kiküldése
                    </button>
                </div>
                <div className="processMsg">
                    {sending && "Küldés folyamatban..."}
                </div>
            </div>
            )
        }
        <MyModal 
            isOpen={isOpen}
            onClose={closeDialog}
            content={dialogContent}
        />
      </div>
      
    </div>
  )
}

export default SendNewsletter
