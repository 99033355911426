import React, {useState, useEffect} from 'react'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import MyModal from './MyModal'
import { useNavigate } from 'react-router-dom'


function CreateNewletter() {
    
    const [cookies] = useCookies(["accessToken"])
    const defaultNewsletter = {
        subject: "",
        mailBody: "",
        dateToSend: "",
        imageUrl: "",
    }
    const [newsletter, setNewsletter] = useState(defaultNewsletter)
    const [loading, setIsloading] = useState(false)
    const [numberOfRecipients, setNumberOfRecipients] = useState(undefined)
    const [error, setError] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const navigate = useNavigate()
    const [reLoginNeeded, setReLoginNeeded] = useState(false)

    useEffect(()=>{
        async function fetchData() {
            setIsloading(true)
            try {
                const url = `${process.env.REACT_APP_API_URL}/numberofrecipients`;
                const {data} = await axios.get(url, 
                    { headers: 
                        { Authorization: `Bearer ${cookies.accessToken}` }
                    }
                )
                if (data?.msg) 
                    setNumberOfRecipients(data.msg)
            } catch(err) {
                //console.log(err)
                setError(err?.response?.data ?? err?.message ?? "Hiba")
                if (err?.response?.status === 401) {
                    setDialogContent(
                        err?.response?.data ??
                        err?.message ??
                        "Váratlan hiba történt."
                    )
                    setReLoginNeeded(true)
                    openDialog()
                }
            } finally {
                setIsloading(false)
            }
        }

        fetchData();
    },[]);

    /* useEffect(()=>{
        console.log(newsletter)
    }, [newsletter]) */

    const openDialog = () => {
        setIsOpen(true)
    }

    const closeDialog = () => {
        setIsOpen(false)
        if (error !== "" && reLoginNeeded) {
            //ha valamilyen hibát () jeleztünk ki a Dialog-al, akkor:
            navigate("/login")
        }
        setNewsletter(defaultNewsletter)
    }

    const handleChange=(e)=>{
        const {name, value} = e.target;
        setNewsletter(prev => {
            return {...prev, [name]: value}
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const url = `${process.env.REACT_APP_API_URL}/newsletter/save`
        setError("")
        setIsloading(true)
        try {
            const {data} = await axios.post(
                url, 
                newsletter,
                { headers: 
                    { Authorization: `Bearer ${cookies.accessToken}` }
                }
            )
            //console.log(data)
            setDialogContent(data?.msg ?? "Váratlan hiba")
            openDialog()
        } catch (err) {
            setError(
                err?.response?.data ?? 
                err?.message ?? 
                "Váratlan hiba történt")
            if (err?.response?.status === 401) {
                setDialogContent(
                    err?.response?.data ??
                    err?.message ??
                    "Váratlan hiba történt."
                )
                setReLoginNeeded(true)
                openDialog()
            }
        } finally {
            setIsloading(false)
        }
    }

    return (
        <div>
            <h2>Hírlevél létrehozása</h2>
            {error === "" ?
                (<div className="newsletter-form-container">
                    {numberOfRecipients && 
                        (<div className='numberOfRec'>
                            {numberOfRecipients} cím van jelenleg az adatbázisban
                        </div>)}
                    <form onSubmit={e=>handleSubmit(e)} className='newsletter-form'>
                        <div className="subject">
                            <label htmlFor='subject'>Hírlevél tárgya</label>
                            <input 
                                type="text" 
                                name="subject"
                                id="subject" 
                                value={newsletter.subject}
                                onChange={e=>handleChange(e)}
                                required
                            />
                        </div>
                        <div className="dateToSend">
                            <label>Kiküldés dátuma</label>
                            <input 
                                type="date" 
                                name="dateToSend" 
                                value={newsletter.dateToSend}
                                onChange={e=>handleChange(e)}
                                required
                            />
                        </div>
                        <div className="imageUrl">
                            <label>Csatolt kép linkje</label>
                            <input 
                                type="text" 
                                name="imageUrl" 
                                value={newsletter.imageUrl}
                                onChange={e=>handleChange(e)}
                                maxLength={300}
                            />
                        </div>
                        <div className="mailBody">
                            <label>Hírlevél szövege:</label>
                            <textarea 
                                name="mailBody" 
                                value={newsletter.mailBody}  
                                rows="20"
                                onChange={e=>handleChange(e)}
                                required
                            >
                            </textarea>
                        </div>
                        <div>
                            <input 
                                type="submit" 
                                value="Mentés" 
                                disabled={loading} 
                            />
                        </div>
                    </form>
                </div>)
                :
                (<div className="error">
                    {error}
                </div>)
            }
            <MyModal 
                isOpen={isOpen}
                onClose={closeDialog}
                content={dialogContent}
            />
        </div>
  )
}

export default CreateNewletter
