import { useEffect } from 'react';
import './App.css';
import Layout from './components/Layout';
import Login from './components/Login';
import SideMenu from './components/SideMenu';
import SendNewsletter from './components/SendNewsletter';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CreateNewletter from './components/CreateNewletter';

function App() {

  const [cookies, setCookies] = useCookies(['accessToken'])
  const navigate = useNavigate()

  useEffect(()=>{
    if (!cookies.accessToken) {
      navigate("/login")    
    }
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route 
          path='/'
          element={<Layout main={<SideMenu />}/>}
        />
        <Route 
          path='/login'
          element={<Login />}
        />
        <Route 
          path='/create'
          element={<Layout main={<><SideMenu /><CreateNewletter /></>}/>}
        />
        <Route 
          path='/send'
          element={<Layout main={<><SideMenu /><SendNewsletter /></>}/>}
        />
        <Route 
          path='*'
          element={<Layout />}
        />
      </Routes>
    </div>
  );
}

export default App;
