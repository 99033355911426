import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

function Login() {

    const [authData, setAuthData] = useState({
        username: "",
        password: ""
    })
    const [result, setResult] = useState(null)
    const [error, setError] = useState("")
    const navigate = useNavigate()
    const [cookies, setCookies] = useCookies(["accessToken"])
    
    /* useEffect(()=>{
        console.log(authData)
    }, [authData]) */

    const handleChange = (e) => {
        const {name, value} = e.target;
        setAuthData(prev => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const clearPassword = () => {
        setAuthData(prev => {
            return {
                ...prev, password: ""
            }
        })
    }

    async function login() {
       try {
            const url = `${process.env.REACT_APP_API_URL}/user/login`
            const res = await axios.post(url, authData, {withCredentials: true})
            if (res?.data?.accessToken) {
                //1. token mentése
                //console.log(res?.data?.accessToken)
                setCookies('accessToken', res.data.accessToken)
                //2. navigálás a '/' útvonalra
                navigate('/create')
            } else {
                setError('Hiba: hiányzó token')
                clearPassword()
            }
        } catch (err) {
            console.log(err)
            setError(err?.response?.data?.msg ?? "Egyéb hiba")
            clearPassword()
       } 
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        login();
    }

  return (
    <div className='login-container'>
      <h1>Bejelentkezés</h1>
      <div>
        <form onSubmit={e=>handleSubmit(e)} className='loginForm'>
            <input 
                type="text" 
                name="username"
                value={authData.username} 
                onChange={e=>handleChange(e)}
                placeholder='Felhasználónév'
                required
            />
            <input 
                type="password" 
                name="password"
                value={authData.password}
                onChange={e=>handleChange(e)}
                placeholder='Jelszó'
                required
            />
            <input type="submit" value="Belépés" />
        </form>
        <div className="error">{error}</div>
      </div>

    </div>
  )
}

export default Login
