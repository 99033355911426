import React from 'react'

function Footer() {
  return (
    <footer>
        Anna Vendégház - Szeleste
    </footer>
  )
}

export default Footer
