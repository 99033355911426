import React from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

function Header() {

  const [cookies, setCookies, removeCookie] = useCookies(['accessToken'])
  const navigate = useNavigate()

  const logout = () => {
    setCookies('accessToken', '')
    removeCookie('accessToken')
    navigate('/login')
  }

  return (
    <header>
      <div className="exit">
        <button onClick={logout}>Kilépés</button>
      </div>
      <div className="logo">
        Hírlevél küldő alkalmazás
      </div>
    </header>
  )
}

export default Header
