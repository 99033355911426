import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'

function TestEmail( {newsletterId} ) {

    const [isTestEmail, setIsTestEmail] = useState(false)
    const [testAddress, setTestAddress] = useState("")
    const [cookies] = useCookies(["accessToken"])
    const [sending, SetIsSending] = useState(false)
    const [error, setError] = useState("")
    const [response, setResponse] = useState("")

    /* useEffect(()=>{
        if (newsletterId.length < 1) {
            setError("")
        }
    }, [newsletterId]); */


    async function sendTestEmailRequest() {
        SetIsSending(true)
        setError("")
        const url = `${process.env.REACT_APP_API_URL}/newsletter/send-v2`;
        try {
            const {data} = await axios.post(
                url, 
                {
                    data: newsletterId, 
                    testAddress
                },
                { headers: 
                    { Authorization: `Bearer ${cookies.accessToken}` }
                }
            )
            //console.log(data)
            if (Array.isArray(data?.msg)) {
                setResponse(data.msg[0]?.value ?? "Váratlan eredmény")
            } else {
                setError(data?.msg ?? "Hiba a levelek küldése során")
            }
        } catch (err) {
            setError(err?.response?.data ?? err?.message ?? "Hiba")
            if (err?.response?.status === 401) {
                
            }
        } finally {
            SetIsSending(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        /* console.log(newsletterId)
        console.log(testAddress) */
        sendTestEmailRequest()
    }

    return (
        <div className='testEmailContainer'>
            <div className="testInfo">
                <h3>Teszt küldési lehetőség</h3>
                <p>
                Az éles kiküldés előtt van lehetőség egy megadott email címre egy teszt küldést kérni, ahol látható lesz, hogy pontosan mi és hogyan jelenik meg majd emailekben. A teszt email tartalma mindig az adott oldalon megjelenő első azonosítójú email tartalma lesz.
                </p>
                <p>
                A teszt küldéséhez pipáld ki a 'Teszt Email küldését', adj meg egy általad elérhető email címet és kattins a 'Teszt küldése' gombra.
                </p>
            </div>
            <form onSubmit={e => handleSubmit(e)} className='testEmailForm'>
                <div>
                    <input 
                        type='checkbox'
                        name='isTestEmail'
                        id='isTestEmail'
                        checked={isTestEmail}
                        onChange={e=>setIsTestEmail(prev=>!prev)}
                    />
                    <label 
                        htmlFor="isTestEmail" 
                        className='noGrid'
                    >Teszt Email küldése
                    </label>
                </div>
                <div>
                    <input 
                        type="text" 
                        name="testAddress" 
                        id="testAddress" 
                        value={testAddress}
                        onChange={e=>setTestAddress(e.target.value)}
                        disabled={!isTestEmail}
                        required
                        className='testAddress'
                        placeholder='email cím'
                    />
                </div>
                <div>
                    <input 
                        type="submit" 
                        name="testAddress" 
                        value={sending ? "Küldés folyamatban..." : "Teszt küldése"}
                        disabled={!isTestEmail || sending}
                    />
                </div>
            </form>
            {error !=="" &&
                <div className='error'>
                    {error}
                </div>
            }
            {response !== "" && 
                <div className='response'>
                    {response}
                </div>
            }
        </div>
    )
}

export default TestEmail
