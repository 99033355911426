import React from 'react'
import { Link } from 'react-router-dom'

function SideMenu() {
  return (
    <div className='sidemenu-container'>
        <h2>Menü</h2>
        <nav>
            <ul className='sidemenu'>
                <li><Link to={"/create"}>Hírlevél létrehozás</Link></li>
                <li><Link to={"/send"}>Hírlevél kiküldés</Link></li>
            </ul>
        </nav>
    </div>
  )
}

export default SideMenu
